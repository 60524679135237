<template>
	<!-- 报事/报修 -->
	<div class="page_main">
		<van-form @submit="onSubmit">
			<div class="title_area fs_14">报修单号：{{ formObj.repairNo }}</div>
			<van-field v-model="formObj.contact" name="contact" :readonly="isReadleOnly" label="联系人" placeholder="请输入联系人" />

			<van-field v-model="formObj.contactPhone" name="contactPhone" :readonly="isReadleOnly" label="联系电话" placeholder="请输入联系电话" />
			<div class="title_area fs_14">报修信息</div>
			<van-field readonly clickable name="repaire" :value="formObj.buildingName" label="楼栋名称"/>
			<van-field v-model="formObj.repairLocation" name="repairLocation" :border="false" :readonly="isReadleOnly" label="报修地点" placeholder="请输入报修地点" />
			<van-field readonly clickable name="repaire" :value="formObj.repaireTypeText" label="报修类型" @click="showPicker = true" />
			<!-- <van-popup v-model="showPicker"
                 :readonly="isReadleOnly"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false" />
      </van-popup> -->

			<van-field readonly clickable name="repair" :value="formObj.repairPartText" label="报修位置" @click="showPickerP = true" />
			<!-- <van-popup v-model="showPickerP"
                 :readonly="isReadleOnly"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="repairPartAry"
                    @confirm="onConfirmP"
                    @cancel="showPickerP = false" />
      </van-popup> -->

			<!-- <div class=" mt_8"></div> -->

			<van-field
				v-model="formObj.description"
				name="description"
				label="问题描述"
				rows="2"
				:readonly="isReadleOnly"
				autosize
				type="textarea"
				:border="false"
				show-word-limit
				placeholder="请输入问题描述"
			/>

			<!-- <div class=" mt_8"></div> -->

			<van-field name="uploader" :border="false" label="">
				<template #input>
					<!-- <van-uploader :max-count="3"
                        :after-read="afterRead"
                        :before-delete="beforeDelete"
                        v-model="formObj.photosAry" /> -->
					<van-image v-for="(item, index) in formObj.images" :key="index" width="33.33%" @click="onChooseItem(item, index)" :src="item" />
				</template>
			</van-field>

			<template v-if="formObj.scheduleStatus == 9 || formObj.scheduleStatus == 6">
				<div class="title_area fs_14">维修信息</div>
				<van-field
					v-model="repairRecord.remark"
					name="description"
					label="维修内容"
					rows="2"
					:readonly="isReadleOnly"
					autosize
					type="textarea"
					:border="false"
					show-word-limit
					placeholder=""
				/>
				<van-field name="uploader" :border="false" label="">
					<template #input>
						<van-image v-for="(item, index) in (repairRecord.photos || '').split(',')" :key="index" width="33.33%" @click="onChooseItem(item, index)" :src="item" />
					</template>
				</van-field>
			</template>


			<template v-if="!!formObj.evaluateScore && formObj.scheduleStatus == 9">
				<div class="title_area fs_14">评价信息</div>
				<van-field name="evaluateScore" label="评分">
					<template #input>
						<van-rate v-model="formObj.evaluateScore" readonly />
					</template>
				</van-field>
				<van-field
					v-model="formObj.evaluateContent"
					name="description"
					label="评价内容"
					rows="2"
					:readonly="isReadleOnly"
					autosize
					type="textarea"
					:border="false"
					show-word-limit
					placeholder=""
				/>
				<van-field name="uploader" :border="false" label="">
					<template #input>
						<van-image v-for="(item, index) in formObj.evaluatePicture" :key="index" width="33.33%" @click="onChooseItem(item, index)" :src="item" />
					</template>
				</van-field>
			</template>

			<template v-if="!!formObj.returnVisitName">
				<div class="title_area fs_14">回访信息</div>
				<van-field v-model="formObj.returnVisitName" name="returnVisitName" :border="false" :readonly="isReadleOnly" label="回访人" placeholder="" />
				<van-field v-model="formObj.returnVisitResult" name="returnVisitResult" :border="false" :readonly="isReadleOnly" label="回访结果" placeholder="" />
				<van-field v-model="formObj.returnVisitTime" name="returnVisitTime" :border="false" :readonly="isReadleOnly" label="回访时间" placeholder="" />
				<van-field v-model="formObj.returnVisitTypeText" name="returnVisitTypeText" :border="false" :readonly="isReadleOnly" label="回访状态" placeholder="" />
			</template>
			<div class="schedule">
				<div class="schedule_title">维修进度</div>
				<van-steps direction="vertical" :active="repairHistory.length">
					<van-step v-for="(item, index) in repairHistory" :key="index">
						<div class="schedule_details">{{ item.createTime }}</div>
						<div class="schedule_details">{{ item.description }}</div>
					</van-step>
				</van-steps>
			</div>
		</van-form>
		<div style="margin: 16px;"
				v-if="this.formObj.scheduleStatus == 0 || this.formObj.scheduleStatus == 1 || this.formObj.scheduleStatus == 2 || this.formObj.scheduleStatus == 4"
		>
			<van-button
				block
				:loading="isDisabled"
				type="info"
				color="#21AA61"
				native-type="submit"
				@click="cancelRepair"
			>
				取消报修
			</van-button>
		</div>
		<div style="margin: 16px;" v-if="this.formObj.scheduleStatus == 6">
			<van-button block type="info" color="#21AA61" native-type="submit" @click="clickPj(formObj.uuid)">评价</van-button>
		</div>
	</div>
</template>
<script>
import { initDictOptions } from '@/api/login.js';
import { report } from '@/api/upload.js';
import { getByApplyUuid } from '@/api/repairsRecord.js';

import { commitRepairs, repairDetail, repairHistory, cancelRepair } from '@/api/repairs.js';
import { Toast } from 'vant';
import { ImagePreview } from 'vant';
export default {
	props: {},
	components: {},
	data() {
		return {
			isReadleOnly: true,
			// form表单所有数据
			formObj: {
				codeNum: 1234567890,
				repaireTypeText: '', // 报修类型
				repaireType: '', // 报修类型
				repairLocation: '', // 报修地点
				repairPartText: '', // 报修位置
				repairPart: '', // 报修位置
				contact: '', // 联系人
				contactPhone: '', // 联系电话
				description: '', // 问题描述
				photosAry: [], // 上传照片
				photoss: [], // 上传照片
				images: [],
				evaluatePicture: []
			},
			repairHistory: [],
			repairRecord: {},
			startPosition: 1,
			columns: [],
			repairPartAry: [],
			showPicker: false,
			showPickerP: false,
			isDisabled: false
		};
	},
	created() {
		const id = this.$route.params.id;
		var that = this;
		Promise.all([this.getLocation(), this.getType()]).then(function(values) {
			console.log(values);
			that.getRepairDetail();
		});
	},
	mounted() {},
	methods: {
		// 返回上一页
		onClickLeft() {
			history.back();
		},
		// 报修类型选择确定
		onConfirm(v) {
			this.formObj.repaireTypeText = v.text;
			this.formObj.repaireType = v.value;
			this.showPicker = false;
		},
		onConfirmP(v) {
			this.formObj.repairPartText = v.text;
			this.formObj.repairPart = v.value;
			this.showPickerP = false;
		},
		// 上传图片
		afterRead(file) {
			file.status = 'uploading';
			file.message = '上传中...';
			// 此时可以自行将文件上传至服务器
			// console.log(file.file);
			report({ file: file.file, action: 'repair_apply' }).then(res => {
				// console.log(res)
				const data = res.data;
				console.log(data.statusCode);
				if (data.statusCode == 200) {
					this.formObj.photoss.push({
						url: data.data.fileName
					});
					console.log('123');
					file.status = 'done';
				} else {
					file.status = 'failed';
					file.message = '上传失败';
				}
			});
		},
		getRepairDetail() {
			var that = this;
			Promise.all([repairDetail({ uuid: this.$router.currentRoute.query.uuid }), repairHistory({ repairApplyId: this.$router.currentRoute.query.uuid })]).then(function(
				values
			) {
				console.log(values);
				if (values[0].code == 200) {
					that.formObj = Object.assign(that.formObj, values[0].data);
					that.formObj.images = that.formObj.photos ? that.formObj.photos.split(',') : [];
					that.formObj.evaluatePicture = that.formObj.evaluatePicture ? that.formObj.evaluatePicture.split(',') : [];
					var repaireType = that.columns.find(item => item.value == that.formObj.repaireType);
					var repairPart = that.repairPartAry.find(item => item.value == that.formObj.repairPart);
					console.log(repaireType);
					console.log(repairPart);
					that.formObj.repaireTypeText = repaireType?.text;
					that.formObj.repairPartText = repairPart?.text;
					that.getRepairRecord()
				}
				if (values[1].code == 200) {
					that.repairHistory = values[1].data;
				}
			});
		},
		getRepairRecord(){
			(this.formObj.scheduleStatus == 9 || this.formObj.scheduleStatus == 6) && getByApplyUuid(this.formObj.uuid).then((res)=>{
				this.repairRecord = res.data
			})
		},
		cancelRepair() {
			cancelRepair({ uuid: this.$router.currentRoute.query.uuid })
				.then(res => {
					console.log(res);
					if (res.code == 200) {
						Toast.success('提交成功');
						this.getRepairDetail();
					} else {
						Toast.fail(res.data.message);
					}
				})
				.finally(() => {});
		},
		// 删除照片
		beforeDelete(a, b, c) {
			console.log(a, b, c);
			if (a.status == 'done') {
				this.formObj.photoss.splice(b.index, 1);
				return true;
			} else {
				return true;
			}
		},
		// 表单提交
		onSubmit() {
			console.log(this.formObj);
			// formObj: {
			//   repaire: '', // 报修类型
			//   repaireType: '', // 报修类型
			//   repairLocation: '', // 报修地点
			//   repair: '', // 报修位置
			//   repairPart: '', // 报修位置
			//   contact: '', // 联系人
			//   contactPhone: '', // 联系电话
			//   description: '', // 问题描述
			//   photosAry: [], // 上传照片
			//   photoss: [] // 上传照片
			// }
			const ary = [];
			this.formObj.photoss.forEach(item => {
				ary.push(item.url);
			});
			this.formObj.photos = ary.join(',');
			this.isDisabled = true;
			commitRepairs(this.formObj)
				.then(res => {
					console.log(res);
					if (res.data.code == 200) {
						Toast.success('提交成功');
						setTimeout(() => {
							history.back();
						}, 1000);
					} else {
						Toast.fail(res.data.message);
					}
				})
				.finally(() => {
					this.isDisabled = false;
				});
		},
		// 获取报修类型字典
		getType() {
			return new Promise(resolve => {
				initDictOptions('zm_repairs_type').then(res => {
					const ary = [];
					res.forEach(item => {
						ary.push({
							text: item.text,
							value: item.value
						});
					});
					this.columns = ary;
					resolve(1);
				});
			});
		},
		// 报修位置
		getLocation() {
			return new Promise(resolve => {
				initDictOptions('zm_repair_location').then(res => {
					// console.log(res)
					const ary = [];
					res.forEach(item => {
						ary.push({
							text: item.text,
							value: item.value
						});
					});
					this.repairPartAry = ary;
					resolve(1);
				});
			});
		},
		onChooseItem(item, index) {
			ImagePreview({
				images: this.formObj.images,
				startPosition: index
			});
		},
		//评价
		clickPj(uuid) {
			this.$router.push({
				name: 'serveEvaluateAdd',
				query: {
					uuid: uuid
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/ .van-field__label {
	color: #333333 !important;
	font-size: 16px;
}
.page_main {
	background-color: #f7f8fa;
	height: 100%;
	min-height: 100vh;
}
.title_area {
	height: 34px;
	line-height: 34px;
	background-color: #f7f8fa;
	color: #999999;
	padding: 0 14px;
}
.schedule {
	margin-top: 10px;
	background-color: #ffffff;
	.schedule_title {
		padding: 0 14px;
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		line-height: 44px;
	}
	.schedule_details {
		font-size: 12px;
		color: #888888;
	}
}
</style>
