import { request, requestNoApi } from "@/utils/request";
// let res = await getBaseList('/system/dicSet/getDicItemByCode',{code:dictCode});
// return res;
const repairsApi = {
  getByApplyUuid: "/room/zmRepairRecord/getDetailByRepairApplyId",
};

// 根据报修申请单Id查询维修单信息
export function getByApplyUuid(applyUuid) {
  return request({
    url: repairsApi.getByApplyUuid,
    method: "get",
    params: {repairApplyId: applyUuid},
  });
}